import React from "react";
export default function AccessDenied(props) {
    return (
        <>
            <div className='display-container'>
                <h1 className='header'>{props.sectionHeader}</h1>
                <h2 className='header'>Access Denied!</h2>
                <p>Please contact staff for permission to access this section of the employee dashboard.</p>
            </div>
        </>
    )
}