import React, { useState } from 'react';
import axios from 'axios';
import './UserControl.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';

import { Fade } from 'react-reveal';
import NotesDisplay from '../GeneralDisplays/NotesDisplay';
import UserSearch from './UserSearch';
import ChangePermissions from './ChangePermissions';

axios.defaults.withCredentials = true;

const nothing = 0;
const normal = 1;
const employees = 2;
const all = 3;
const search = 4;

const notes = 11;
const makeEmployee = 12;
const displayChangePermissions = 13;

export default function UserControl(props) {
    // Local State for Users
    const [users, setUsers] = useState(props.users);

    const [showing, setShowing] = useState(nothing);
    const [opened, setOpened] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [controlShowing, setControlShowing] = useState("");
    const [key, setKey] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [employeeSubmitted, setEmployeeSubmitted] = useState(false);

    // Update Users
    const updateUsers = (newUser) => {
        // Update Parent State
        props.setUsers((prevUsers) =>
            prevUsers.map((user) =>
                user.email === newUser.email ? newUser : user
            )
        );

        // Update Local State
        setUsers((prevUsers) =>
            prevUsers.map((user) =>
                user.email === newUser.email ? newUser : user
            )
        );
    };

    // Events

    const onNotesUpdate = (notes) => {
        selectedUser.notes = notes;
    }

    const onEmployeeSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('key', key);
        formData.append('id', opened);

        axios.post('/api/make-user-employee', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setEmployeeSubmitted(true);
            } else {
                setError(true);
                setErrorMsg(res.data.message);
            }
        })
    }

    // Renders

    const renderControl = () => {
        return (
            <div className='user-control'>
                <div className='control-items'>

                    <button className='pending-button'
                        onClick={() => {
                            if (showing != normal) {
                                setShowing(normal);
                            } else {
                                setShowing(nothing);
                            }
                        }}>
                        View Normal Users
                    </button>
                    <button className='approved-button'
                        onClick={() => {
                            if (showing != employees) {
                                setShowing(employees);
                            } else {
                                setShowing(nothing);
                            }
                        }}>
                        View Employees
                    </button>

                    <button className='all-button'
                        onClick={() => {
                            if (showing != all) {
                                setShowing(all);
                            } else {
                                setShowing(nothing);
                            }
                        }}>
                        View All Users
                    </button>

                    <button className='search-button'
                        onClick={() => {
                            if (showing != search) {
                                setShowing(search);
                            } else {
                                setShowing(nothing);
                            }
                        }}>
                        Search
                    </button>
                </div>
            </div>
        )
    }

    const renderEmployeeMessage = () => {
        if (error) {
            return <h2 className='header'>{errorMsg}</h2>
        } else {
            if (employeeSubmitted) {
                return <h2 className='header'>Successfully Made User Employee!</h2>
            }
        }
    }

    const renderUserControl = () => {
        switch (controlShowing) {
            case (nothing):
                return ""

            case (notes):
                return (
                    <>
                        <NotesDisplay notes={selectedUser.notes} userNotes={true} applicationNotes={false}
                            onNotesUpdate={onNotesUpdate} id={opened} />
                    </>
                )

            case (makeEmployee):
                return (
                    <>
                        <div className='make-employee'>
                            <h1 className='header'>Make User Employee</h1>
                            <div className='response-password-input-container'>
                                <label>
                                    Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                    <Password type="key" name="key" value={key} onChange={(event) => {
                                        setKey(event.target.value);
                                        setError(false);
                                    }} toggleMask={true} />
                                </label>

                                {renderEmployeeMessage()}

                                <button className='approved-button' onClick={onEmployeeSubmit}>Verify & Make Employee</button>
                            </div>
                        </div>
                    </>
                )

            case (displayChangePermissions):
                return (
                    <>
                        <ChangePermissions userData={selectedUser} />
                    </>
                )
        }
    }

    const renderUserInformation = (user) => {
        if (user.employee) {
            return (
                <>
                    <div className='application-info-list'>
                        <div className='application-info-item'>
                            <h2 className='header'>Full Name: </h2> <p>{user.firstName} {user.lastName}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2 className='header'>Email: </h2> <p>{user.email}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2 className='header'>Permissions:</h2>
                            <ul className='permissions-list'>
                                {user.clientSidePermissions.map((item, index) => {
                                    return (
                                        <>
                                            <li>
                                                <h3>{item}</h3>
                                            </li>
                                        </>
                                    )

                                })}
                            </ul>
                        </div>
                    </div>
                </>
            )
        }
        // Normal user info
        return (
            <>
                <div className='application-info-list'>
                    <div className='application-info-item'>
                        <h2>Account Verified: </h2> <p>{user.verified ? "Yes" : "No"}</p>
                    </div>

                    <div className='application-info-item'>
                        <h2>Employee: </h2> <p>{user.employee ? "Yes" : "No"}</p>
                    </div>

                    <div className='application-info-item'>
                        <h2>Newsletter: </h2> <p>{user.newsletter ? "Yes" : "No"}</p>
                    </div>

                    <div className='application-info-item'>
                        <h2>Assistance Recieved: </h2> <p>{user.assistanceRecieved}</p>
                    </div>

                    <div className='application-info-item'>
                        <h2>Applications Submitted: </h2> <p>{user.applicationTokens.length}</p>
                    </div>

                    <div className='application-info-item'>
                        <h2>Amount Donated: </h2> <p>{user.amountDonated}</p>
                    </div>
                </div>
            </>
        )
    }

    const renderUserContainerButtons = (user) => {
        if (user.employee) {
            return (
                <>
                    <div className='permission-control-buttons'>
                        <button className='approved-button change-permissions-button' onClick={() => {
                            if (controlShowing === displayChangePermissions) {
                                setControlShowing(nothing);
                            } else {
                                setControlShowing(displayChangePermissions);
                            }
                        }}>Change Permissions</button>
                    </div>
                </>
            )
        }

        // Normal user buttons
        return (
            <>
                <div className='user-control-buttons'>
                    <div className='user-control-item'>
                        <button className='approved-button' onClick={() => {
                            if (controlShowing === notes) {
                                setControlShowing(nothing);
                            } else {
                                setControlShowing(notes);
                            }
                        }}>View / Add Notes</button>
                    </div>

                    <div className='user-control-item'>
                        <button className='approved-button' onClick={() => {
                            if (controlShowing === makeEmployee) {
                                setControlShowing(nothing);
                            } else {
                                setControlShowing(makeEmployee);
                            }
                        }}>Make User Employee</button>
                    </div>
                </div>
            </>
        )
    }

    const renderUser = (user) => {
        if (opened === user._id) {
            return (
                <li className='application-container' key={user._id}>
                    <button className={user.employee ? 'application all-button' : "application approved-button"}
                        onClick={() => {
                            setOpened('');
                            setSelectedUser(null);
                            setControlShowing(nothing);
                        }}>
                        <i class="fa-solid fa-angle-down" />
                        {user.firstName} {user.lastName} | {user.email} | Registered on - {user.dateRegistered} | Notes - {user.notes.length}
                    </button>

                    {renderUserInformation(user)}

                    {renderUserContainerButtons(user)}

                    {renderUserControl()}

                </li>
            )
        } else {
            return (
                <li className='application-container' key={user._id}>
                    <button className={user.employee ? 'application all-button' : "application approved-button"}
                        onClick={() => {
                            setOpened(user._id);
                            setSelectedUser(user);
                            setControlShowing(nothing);
                        }}>
                        <i class="fa-solid fa-angle-right" />
                        {user.firstName} {user.lastName} | {user.email} | Registered on - {user.dateRegistered} | Notes - {user.notes.length}
                    </button>
                </li>
            )
        }
    }

    const renderSelection = () => {
        switch (showing) {
            case (normal):
                return (
                    <ul className="applications-list">
                        {users.map((item, index) => {
                            if (!item.employee) {
                                return (
                                    <Fade>
                                        {renderUser(item)}
                                    </Fade>
                                )
                            }

                        })
                        }
                    </ul>
                )

            case (employees):
                return (
                    <ul className="applications-list">
                        {users.map((item, index) => {
                            if (item.employee) {
                                return (
                                    <Fade>
                                        {renderUser(item)}
                                    </Fade>
                                )
                            }

                        })
                        }
                    </ul>
                )

            case (all):

                return (
                    <ul className="applications-list">
                        {users.map((item, index) => {
                            return (
                                <Fade>
                                    {renderUser(item)}
                                </Fade>
                            )
                        })
                        }
                    </ul>
                )

            case (search):
                return <UserSearch users={users} renderUser={renderUser} />

        }
    }

    return (
        <>
            <div className='display-container'>
                <h1 h1 className='header'>Users</h1>
                {renderControl()}
                {renderSelection()}
            </div>
        </>
    )

}