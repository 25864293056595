import React, { useState } from "react";
import axios from "axios";

import { Password } from "primereact/password";

import AttachmentDownload from "../../../General/AttachmentDownload";
import AttachmentContainer from "../../../General/AttachmentContainer";
import AttachmentDelete from "../../../General/AttachmentDelete";

const nothing = 0;
const deleteImages = 1;
const addImages = 2;
const verifyDeletion = 3;
const imagesUploaded = 4;
const deletionConfirmed = 5;

export default function EventPicturesAdd(props) {
    // States
    const [showing, setShowing] = useState(nothing);
    const [key, setKey] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [pendingFile, setPendingFile] = useState(null);
    const [attachmentError, setAttachmentError] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    // Deletion
    const [forDeletion, setForDeletion] = useState([]);

    // Events

    const onSubmitAttachments = (event) => {
        event.preventDefault();
        const formData = new FormData();
        for (const attachment of attachments) {
            formData.append('eventImages', attachment);
        }
        formData.append('id', props.event._id);
        axios.post('/api/add-event-images', formData, {
            withCredentials: true,
        }).then((res) => {
            if (res.data.status) {
                setError(false);
                setShowing(imagesUploaded);
                props.event.images = res.data.images;
            } else {
                setError(true);
                setErrorMsg(res.data.message);
            }
        })
    }

    const onDeleteImages = (event) => {
        event.preventDefault();
        const newImages = [];
        props.event.images.forEach(element => {
            if (!forDeletion.includes(element)) {
                newImages.push(element);
            }
        });
        axios.post('/api/delete-event-images', {
            withCredentials: true,
            data: {
                key: key,
                newImages: newImages,
                id: props.event._id,
            }
        }).then((res) => {
            if (res.data.status) {
                setError(false);
                setShowing(deletionConfirmed);
                props.event.images = res.data.images;
            } else {
                setError(true);
                setErrorMsg(res.data.message);
            }
        })
    }

    const onFileUpload = (event) => {
        setPendingFile(event.target.files[0]);
        event.target.value = null;
    }

    const onFileAttach = () => {
        if (pendingFile != null) {
            setAttachments(attachments.concat(pendingFile));
            setPendingFile(null);
        }
    }

    const onPendingDelete = () => {
        setPendingFile(null);
    }

    const onFileDelete = (file) => {
        const newAttachments = attachments.filter(attachment => attachment !== file);
        setAttachments(newAttachments);
    }

    const addToDeletion = (image) => {
        setForDeletion(forDeletion.concat(image));
    }

    const removeFromDeletion = (image) => {
        setForDeletion(forDeletion.filter(e => e !== image));
    }

    // Renders

    const renderAttachments = () => {
        if (attachments.length === 0)
            return (
                <ul className='attachments-container'>
                    {props.event.images.map((image, index) => {
                        return (
                            <li key={index} className='attachment-download'>
                                <AttachmentDownload name={image.name} size={image.size}
                                    link={image.data} type={image.contentType}
                                />
                            </li>
                        )
                    })}
                </ul>
            )

        return (
            <div>
                <div>
                    <h1 className='header'>Current Images</h1>
                    <ul className='attachments-container'>
                        {props.event.images.map((image, index) => {
                            return (
                                <li key={index} className='attachment-download'>
                                    <AttachmentDownload name={image.name} size={image.size}
                                        link={image.data} type={image.contentType}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div>
                    <h1 className='header'>New Images</h1>
                    {attachments.map((attachment, index) => {
                        return (
                            <li key={index} className='attachment-download'>
                                <AttachmentContainer attachment={attachment}
                                    pending={false} onPendingDelete={onPendingDelete}
                                    onFileDelete={onFileDelete}
                                />
                            </li>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderPendingFile = () => {
        if (pendingFile) {
            return (
                <>
                    <div>
                        <AttachmentContainer attachment={pendingFile}
                            pending={true} onPendingDelete={onPendingDelete}
                            onFileDelete={onFileDelete}
                        />
                    </div>
                </>
            )
        } else {
            return <h3>Nothing Here!</h3>
        }
    }

    const renderError = () => {
        if (error) {
            return <h2 className="error">{errorMsg}</h2>
        }
    }

    const renderFlaggedImages = () => {
        if (forDeletion.length > 0) {
            return (
                <>
                    {forDeletion.map((image, index) => {
                        return (
                            <h2>{image.name}</h2>
                        )
                    })}
                </>
            )
        }

        return (
            <h3>None Yet!</h3>
        )
    }

    const renderDeletionConfirm = () => {
        if (forDeletion.length > 0) {
            return <button className="approved-button" onClick={() => setShowing(verifyDeletion)}>Verify & Delete</button>
        }
        return <button className="denied-button">Verify & Delete</button>
    }

    const renderDeleteButton = () => {
        if (key.length > 0) {
            return <button className='approved-button' onClick={onDeleteImages}>Verify & Delete Images</button>
        } else {
            return <button className='denied-button'>Verify & Delete Images</button>
        }
    }

    const renderComponent = () => {
        switch (showing) {
            case nothing:
                return (
                    <>
                    </>

                )

            case deleteImages:
                if (props.event.images.length === 0) {
                    return (
                        <>
                            <h1 className="header">Nothing to Delete!</h1>
                            <p>Try adding some images first!</p>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div>
                                <h1 className="header">Select Images For Deletion</h1>
                                {props.event.images.map((image, index) => {
                                    return (
                                        <li key={index} className='attachment-download'>
                                            <AttachmentDelete image={image} addToDeletion={addToDeletion} removeFromDeletion={removeFromDeletion}
                                            />
                                        </li>
                                    )
                                })}

                                <h1 className="header">Selected Images: </h1>
                                {renderFlaggedImages()}
                                {renderDeletionConfirm()}
                            </div>

                        </>
                    )
                }

            case addImages:
                return (
                    <>
                        <div className="application-section">
                            <h1 className="header">Add Images</h1>
                            <p>Add desired images below</p>
                            {renderAttachments()}
                            <h1 className="header">Pending Image</h1>
                            {renderPendingFile()}
                            <div className="attachment-button-row">

                                <label onChange={onFileUpload} htmlFor="formId">
                                    <input name="" type="file" id="formId" hidden />
                                    <div className="attachment-button approved-button">
                                        Add File
                                    </div>
                                </label>

                                {attachmentError ? <h2>Error Submitting, Try Again or Contact Support</h2> : ""}

                                <div>
                                    <button className="approved-button"
                                        onClick={onFileAttach}>Attach File</button>
                                </div>
                            </div>

                            {renderError()}
                            <button className={
                                attachments.length > 0 ? 'attachment-submit-button approved-button'
                                    : 'attachment-submit-button denied-button'
                            } onClick={onSubmitAttachments}>Submit Images</button>
                        </div>
                    </>
                )

            // Verify Views

            case verifyDeletion:
                return (
                    <>
                        <div className='pending-verification'>
                            <h1 className='header'>Verify Information</h1>
                            <h1 className='header'>You Are Deleting the Following Files: </h1>
                            {forDeletion.map((image, index) => {
                                return (
                                    <h2>{image.name}</h2>
                                )
                            })}
                            <p>These files not be recoverable, so make sure that you have backups of them if they are otherwise unaccessible.</p>
                            <div className='response-password-input-container'>
                                <label>
                                    Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                    <Password type="password" name="key" value={key} onChange={(event) => setKey(event.target.value)} toggleMask={true} />
                                </label>
                            </div>
                            {renderError()}
                            {renderDeleteButton()}
                        </div>
                    </>
                )

            // Successful Update Views

            case imagesUploaded:
                return (
                    <>
                        <h1 className="header">Images Uploaded!</h1>
                        <p>The images you attached have been uploaded and will be displayed on the events page immediately.</p>
                    </>
                )

                case deletionConfirmed:
                    return (
                        <>
                            <h1 className="header">Images Deleted!</h1>
                            <p>The images you selected have been deleted and will be removed from the website immediately.</p>
                        </>
                    )
        }
    }


    return (
        <>
            <h1 className="header">Add / Delete Event Images</h1>
            <div className="image-nav">
                <button className="approved-button" onClick={() => {
                    setShowing(addImages);
                    setError(false);
                    setErrorMsg("");
                }}>Upload Images</button>
                <button className="denied-button" onClick={() => {
                    setShowing(deleteImages);
                    setError(false);
                    setErrorMsg("");
                }}>Delete Images</button>
            </div>
            {renderComponent()}
        </>
    )
}