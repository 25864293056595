import React, { useState, useEffect } from 'react';
import axios from 'axios';

const viewSponsors = 1;

export default function SponsorControl() {
    const [sponsors, setSponsors] = useState(null);
    const [sponsorsLoading, setSponsorsLoading] = useState(true);
    const [displaying, setDisplaying] = useState(viewSponsors);

    // Get Sponsors
    useEffect(() => {
        if (!sponsors) {
            setSponsorsLoading(true);

            axios.get('/api/sponsors', { withCredentials: true }).then((res) => {
                console.log(res.data.sponsors);
                setSponsors(res.data.sponsors);
                setSponsorsLoading(false);
            });
        }

    }, []);

    const renderSelection = () => {
        switch (displaying) {
            case viewSponsors:
                return (
                    <>

                    </>
                )
        }

    }

    return (
        <>
        <div className='sponsor-header'>
        <h1 className='header'>Sponsors</h1>
        <div className='sponsor-control'>
                <button className='approved-button'>View Sponsors</button>
            </div>
        </div>


            <div className='sponsor-view-container'>
                {renderSelection()}
            </div>
        </>
    )
}