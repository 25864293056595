export const SponsorsData = [
    {
        title: "Rare Patient Voice",
        alt: "rare patient voice logo",
        source: "/images/sponsors/rare-patient-voice.jpg",
        url: "https://rarepatientvoice.com/"
    },
    {
        title: "EJ Pretty",
        alt: "ej pretty logo",
        source: "/images/sponsors/ej-pretty.png",
        url: "https://www.ejprettychicago.com/"
    },
    {
        title: "Paragon Healthcare",
        alt: "paragon healthcare logo",
        source: "/images/sponsors/paragon.png",
        url: "https://paragonhealthcare.com/",
    },
    {
        title: "Octapharma",
        alt: "Octapharma logo",
        source: "/images/sponsors/octapharma.png",
        url: "https://www.octapharmaplasma.com/",
    },
    {
        title: "Medexus Pharma",
        alt: "medexus logo",
        source: "/images/sponsors/medexus.png",
        url: "https://www.medexus.com/en_US",
    },

    {
        title: "Bayer",
        alt: "bayer logo",
        source: "/images/sponsors/bayer.png",
        url: "https://www.bayer.com/en/",
    },
    {
        title: "Sockathon",
        alt: "sockathon logo",
        source: "/images/sponsors/sockathon.png",
        url: "https://try.sockathon.com",
    },
    {
        title: "Novo Nordisk",
        alt: "Novo Nordisk logo",
        source: "/images/sponsors/novo-nordisk.png",
        url: "https://www.novonordisk.com/",
    },
    {
        title: "Sanofi",
        alt: "Sanofi logo",
        source: "/images/sponsors/sanofi.png",
        url: "https://www.sanofi.com/en",
    },
    {
        title: "UNREAL",
        alt: "unreal logo",
        source: "/images/sponsors/unreal.png",
        url: "https://unrealsnacks.com",
    },
    {
        title: "InfuCareRX",
        alt: "InfuCareRX logo",
        source: "/images/sponsors/infucarerx.png",
        url: "https://infucarerx.com/",
    },
    {
        title: "Santa Rosa Pharmacy",
        alt: "Santa Rosa Pharmacy Logo",
        source: "/images/sponsors/srrx.jpg",
        url: "https://santarosarx.com/",
    },

]