import React, { useState, useLayoutEffect, useEffect } from 'react';
import axios from 'axios';

import DashboardWelcome from '../User/DashboardWelcome';
import EmployeeNavbar from './GeneralDisplays/EmployeeNavbar';
import EmployeeApplicationControl from './ApplicationControlDisplays/ApplicationControl';
import EmployeeEventControl from './EventControlDisplays/EventControl';
import EmployeeReportControl from './ReportControlDisplays/ReportControl';
import SettingsControl from './SettingsControlDisplays/SettingsControl';
import AccessDenied from './GeneralDisplays/AccessDenied';
import LoadingSection from './GeneralDisplays/LoadingSection';
import NoSelection from './GeneralDisplays/NoSelection';

import Fade from 'react-reveal/Fade';

import "./EmployeeDashboardStyles.css";

const showNothing = 0;
const showApplications = 1;
const showReports = 2;
const showEvents = 3;
const showSettings = 4;

const applicationAccessKey = 'Application Access';
const eventAccessKey = 'Event Access';
const userAccessKey = 'User Access';
const reportAccessKey = 'Report Access';

function EmployeeDashboard(props) {
    const [display, setDisplay] = useState(showApplications);
    const [legacyApplications, setLegacyApplications] = useState(null);
    const [legacyApplicationsLoading, setLegacyApplicationsLoading] = useState(true);
    const [applications, setApplications] = useState(null);
    const [pendingApplications, setPendingApplications] = useState(null);
    const [events, setEvents] = useState(null);
    const [upcomingEvents, setUpcomingEvents] = useState(null);
    const [reports, setReports] = useState(null);
    const [pendingReports, setPendingReports] = useState(null);
    const [users, setUsers] = useState(null);

    // Loading States
    const [applicationsLoading, setApplicationsLoading] = useState(true);
    const [eventsLoading, setEventsLoading] = useState(true);
    const [reportsLoading, setReportsLoading] = useState(true);
    const [usersLoading, setUsersLoading] = useState(true);

    // Access States
    const [applicationAccess, setApplicationAccess] = useState(null);
    const [eventAccess, setEventAccess] = useState(null);
    const [reportAccess, setReportAccess] = useState(null);
    const [userAccess, setUserAccess] = useState(null);

    useEffect(() => {
        axios.defaults.withCredentials = true;

        // Applications

        if (props.userData.clientSidePermissions.includes(applicationAccessKey)) {
            setApplicationAccess(true);
            if (!applications) {
                axios
                    .get('/api/applications', { withCredentials: true })
                    .then((res) => {
                        // Removed sort by date function, .reverse() works way easier
                        setApplications(res.data.applications.reverse());
                        // Parse applications and get total pending
                        var localPending = 0;
                        res.data.applications.forEach(application => {
                            if (application.status === "Pending") {
                                localPending++;
                            }
                        });

                        setPendingApplications(localPending);
                        setApplicationsLoading(false);
                    })
                    .catch((err) => {
                        setApplicationsLoading(false);
                    });
            }

            // Legacy Applications
            if (!legacyApplications) {
                setLegacyApplicationsLoading(true);
                axios
                    .get('/api/legacy-applications', { withCredentials: true })
                    .then((res) => {
                        setLegacyApplications(res.data.applications.reverse());
                        setLegacyApplicationsLoading(false);
                    })
                    .catch((err) => {
                        setApplicationsLoading(false);
                    });
            }
        } else {
            setApplicationAccess(false);
            setApplicationsLoading(false);
        }

        // Events
        if (props.userData.clientSidePermissions.includes(eventAccessKey)) {
            setEventAccess(true);
            if (!events) {
                setEventsLoading(true);
                axios.get('/api/events', { withCredentials: true })
                    .then((res) => {
                        setEvents(res.data.events);
                        // Parse events and get total upcoming
                        var localUpcoming = 0;
                        res.data.events.forEach(event => {
                            if (event.active) {
                                localUpcoming++;
                            }
                        })
                        setUpcomingEvents(localUpcoming);
                        setEventsLoading(false);
                    })
                    .catch((err) => {
                        setEventsLoading(false);
                    })
            }
        } else {
            setEventAccess(false);
            setEventsLoading(false);
        }


        // Reports
        if (props.userData.clientSidePermissions.includes(reportAccessKey)) {
            setReportAccess(true);
            if (!reports) {
                setReportsLoading(true);
                axios.get('/api/reports', { withCredentials: true })
                    .then((res) => {
                        setReports(res.data.reports.reverse());
                        // Parse reports and get total unresolved
                        var localPending = 0;
                        res.data.reports.forEach(report => {
                            if (!report.resolved) {
                                localPending++;
                            }
                        })
                        setPendingReports(localPending);
                        setReportsLoading(false);
                    })
                    .catch((err) => {
                        setReportsLoading(false);
                    })
            }
        } else {
            setReportAccess(false);
            setReportsLoading(false);
        }

        // Users
        if (props.userData.clientSidePermissions.includes(userAccessKey)) {
            setUserAccess(true);
            if (!users) {
                setUsersLoading(true);
                axios.get('/api/users', { withCredentials: true })
                    .then((res) => {
                        setUsers(res.data.users);
                        setUsersLoading(false);
                    })
                    .catch((err) => {
                        setUsersLoading(false);
                    })
            }
        } else {
            setUserAccess(false);
            setUsersLoading(false);
        }
    }, [applications, events, reports, users])

    const updateDisplay = (value) => {
        setDisplay(value);
    }

    const renderDisplayContainer = () => {
        switch (display) {
            case showNothing:
                return (
                    <div className='display-container'>
                        <h1 className='header'>Nothing Here Yet!</h1>
                        <p>Press a button above to get started.</p>
                    </div>
                )

            case showApplications: {
                // Check For loading
                if (!applicationsLoading && !legacyApplicationsLoading) {
                    // Check for access
                    if (applicationAccess) {
                        return (
                            <EmployeeApplicationControl applications={applications} setApplications={setApplications}
                                legacyApplications={legacyApplications} setLegacyApplications={setLegacyApplications}
                            />
                        )
                    } else {
                        return (
                            <AccessDenied sectionHeader={'Applications'} />
                        )
                    }
                } else {
                    return (
                        <>
                            <LoadingSection sectionHeader={'Applications'} />
                        </>
                    )
                }
            }

            case showReports: {
                // Check for loading
                if (!reportsLoading) {
                    // Check for access
                    if (reportAccess) {
                        return (
                            <EmployeeReportControl reports={reports} setReports={setReports} />
                        )
                    } else {
                        return (
                            <>
                                <AccessDenied sectionHeader={'Reports'} />
                            </>
                        )
                    }

                } else {
                    return (
                        <LoadingSection sectionHeader={'Reports'} />
                    )
                }
            }

            case showEvents: {
                // Check for loading
                if (!eventsLoading) {
                    // Check for access
                    if (eventAccess) {
                        return (
                            <EmployeeEventControl events={events} setEvents={setEvents} />
                        )
                    } else {
                        return (
                            <>
                                <AccessDenied sectionHeader={'Events'} />
                            </>
                        )
                    }

                } else {
                    return (
                        <LoadingSection sectionHeader={'Events'} />
                    )
                }
            }

            case showSettings: {
                // Check for loading
                if (!usersLoading && !eventsLoading && !reportsLoading && !applicationsLoading) {
                    // Check for access
                    if (userAccess, eventAccess, reportAccess, applicationAccess) {
                        return (
                            <SettingsControl users={users} setUsers={setUsers}
                                applications={applications}
                                events={events} reports={reports} legacyApplications={legacyApplications} userData={props.userData}
                            />
                        )
                    } else {
                        return (
                            <>
                                <AccessDenied sectionHeader={'Settings'} />
                            </>
                        )
                    }

                } else {
                    return (
                        <LoadingSection sectionHeader={'Settings'} />
                    )
                }
            }

            default:
                return (
                    <NoSelection />
                )
        }
    }

    return (
        <>
            <Fade>
                <DashboardWelcome fName={props.userData.firstName} />
                <EmployeeNavbar applications={applicationsLoading ? '...' : pendingApplications}
                    reports={reportsLoading ? '...' : pendingReports}
                    events={eventsLoading ? '...' : upcomingEvents}
                    updateDisplay={updateDisplay}
                    showApplications={showApplications}
                    showReports={showReports}
                    showEvents={showEvents}
                    showSettings={showSettings}
                />
            </Fade>

            {renderDisplayContainer()}

        </>
    )
}

export default EmployeeDashboard;