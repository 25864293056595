import React, { useState } from 'react'
import axios from 'axios';
import { Password } from 'primereact/password';
import PermissionContainer from './PermissionContainer';
import './ChangePermissions.css';
axios.defaults.withCredentials = true;

// Store Possible Permissions Clientside
const possiblePermissions = ['Application Access', 'Event Access', 'User Access', 'Report Access',];

// Takes UserData as input
export default function ChangePermissions(props) {
    const [currentPermissions, setCurrentPermissions] = useState(props.userData.clientSidePermissions);
    const [startingPermissions, setStartingPermissions] = useState(props.userData.clientSidePermissions);

    const [key, setKey] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const onUpdatePermissionsSubmit = (event) => {
        const formData = new FormData();
        formData.append('key', key);
        // Stringy to ensure that value remains an array
        formData.append('newClientSidePermissions', JSON.stringify(currentPermissions));
        formData.append('id', props.userData._id);

        axios.post('/api/change-user-permissions', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setError(false);
                setSubmitted(true);
                setSuccessMessage(res.data.message);
            } else {
                setError(true);
                setErrorMessage(res.data.message);
            }
        });
    }

    // Function for removing or adding permissions
    const onPermissionToggle = (value) => {
        if (currentPermissions.includes(value)) {
            // Remove
            setCurrentPermissions(currentPermissions.filter(permission => permission !== value));
        } else {
            // Add
            setCurrentPermissions([...currentPermissions, value]);
        }
    }

    const renderMessage = () => {
        if (error || submitted) {
            return (
                <h2 className={error ? 'error' : submitted ? 'header' : ''}>
                    {error ? errorMessage : submitted ? successMessage : ''}</h2>
            )
        }
    }

    return (
        <>
            <div className='permission-add-container'>
                {possiblePermissions.map((item, index) => {
                    return (
                        <PermissionContainer updatePermissions={onPermissionToggle}
                            enabled={currentPermissions.includes(item)} name={item} />
                    )

                })
                }

                <div className='make-employee'>
                    <div className='response-password-input-container'>
                        <label>
                            Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Password type="key" name="key" value={key} onChange={(event) => {
                                setKey(event.target.value);
                                setError(false);
                            }} toggleMask={true} />
                        </label>

                        {renderMessage()}

                        <button className={currentPermissions !== startingPermissions && key.length > 1 ? "approved-button update-permissions-button" : "denied-button update-permissions-button"} onClick={onUpdatePermissionsSubmit}>Verify & Update Permissions</button>
                    </div>
                </div>
            </div>

        </>
    )
}