import React, { useState } from "react";
// Custom Components
import NoSelection from "../GeneralDisplays/NoSelection";
import UserControl from "../UserControlDisplays/UserControl";
import Statistics from "./Statistics";
import SponsorControl from "./SponsorControl";
// Styles
import './SettingsControl.css';

const showStats = 1;
const showUsers = 2;
const showSponsors = 3;


// Accepts users, setUsers, applications, events, reports, legacyApplications as prop inputs
// Everything else can be called in component for now
export default function SettingsControl(props) {
    const [displaying, setDisplaying] = useState(null);

    const renderSelection = () => {
        switch (displaying) {
            case showStats: {
                return (
                    <>
                        <Statistics users={props.users}
                            applications={props.applications}
                            events={props.events} reports={props.reports} legacyApplications={props.legacyApplications} />
                    </>
                )
            }

            case showUsers: {
                return (
                    <>
                        <UserControl users={props.users} setUsers={props.setUsers} userData={props.userData} />
                    </>
                )
            }

            case showSponsors: {
                return (
                    <>
                        <SponsorControl />
                    </>
                )
            }
            default:
                return (
                    <NoSelection />
                )
        }
    }

    return (
        <>
            <div className='settings-section'>
                <div className='settings-control'>
                    <h1 className="header">Settings</h1>
                    <div className='settings-items'>
                        <button className='approved-button' onClick={() => {
                            setDisplaying(showStats);
                        }}>Website Statistics</button>
                        <button className='approved-button' onClick={() => {
                            setDisplaying(showUsers);
                        }}>User Settings</button>
                        <button className='approved-button' onClick={() => {
                            setDisplaying(showSponsors);
                        }}>Sponsor Settings</button>
                    </div>
                </div>
                <div className='settings-content-display'>
                    {renderSelection()}
                </div>
            </div>
        </>
    )
}