import React from 'react';
import './EmployeeNavbar.css';

export default function EmployeeNavbar(props) {
    return (
        <>

        <div className='employee-navbar-items'>
            <button className={ props.applications > 0 ? "pending-button" : "approved-button"}
                onClick={() => { props.updateDisplay(props.showApplications) }}>
                Applications ({props.applications})</button>

            <button className={ props.events > 0 ? "pending-button" : "approved-button"}
                onClick={() => { props.updateDisplay(props.showEvents) }}>
                Events ({props.events})</button>

            <button className={ props.reports > 0 ? "pending-button" : "approved-button"}
                onClick={() => { props.updateDisplay(props.showReports) }}>
                Reports ({props.reports})</button>

            <button className='approved-button'
                onClick={() => { props.updateDisplay(props.showSettings) }}>
                Settings</button>
        </div>
    </>
    )
}