import React, { useState, useLayoutEffect } from "react";
import axios from 'axios';

// Custom Components
import { StateOptions } from "../../../Apply/StateOptions";

import AttachmentContainer from '../../../General/AttachmentContainer';
import Select from 'react-select';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from "primereact/password";

import "./EventAddStyles.css";

const attachmentSizeError = "Invalid File Size (Max Size: 50MB)!";
const attachmentTypeError = "Invalid File Type!";

// File Types

const allowedTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/webp',
    'application/pdf',
    'application/rtf',
    'application/doc',
    'application/docx',
    'application/txt',
    'application/csv',
    'application/xls',
    'application/xlsx',
    'application/ppt',
    'application/pptx',
];

// Currently 50MB, change the non 1024 number to modify up or down.
const maxSize = 50 * 1024 * 1024;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const trueFalse = 0;
const textQuestion = 1;

const questionOptions = [
    { value: textQuestion, label: "Text Question" },
    { value: trueFalse, label: "Yes or No Question" }
]

function EmployeeEventAdd() {
    // Width / Height
    const [width, height] = useWindowSize();

    // States
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [time, setTime] = useState("");
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(true);
    const [online, setOnline] = useState(false);

    // Questions Section

    const [questions, setQuestions] = useState([]);

    const [instructions, setInstructions] = useState("");
    // Attachments

    const [attachments, setAttachments] = useState([]);
    const [attachmentError, setAttachmentError] = useState(false);
    const [attachmentErrorMessage, setAttachmentErrorMessage] = useState("");
    const [mainImg, setMainImg] = useState(null);

    // Verification
    const [pendingVerification, setPendingVerification] = useState(false);
    const [key, setKey] = useState("");

    // Status
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [submitted, setSubmitted] = useState(false);

    // Select Styling

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0", // Change to your desired hover background color
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#2d3436" : "#fff",
            margin: "12px 0 12px",
            height: width <= 1260 ? "48px" : "55px",
        }),
    };


    // Inputs

    const onNameInput = (event) => {
        setName(event.target.value);
    }

    const onDateInput = (event) => {
        setDate(event.target.value);
    }

    const onDescriptionInput = (event) => {
        setDescription(event.target.value);
    }
    // File Attachments

    const onFileDelete = (file) => {
        setMainImg(null);
    }

    const onFileUpload = (event) => {
        const currentFile = event.target.files[0];
        // Check File Size
        if (currentFile.size <= maxSize) {
            // Check File Type
            if (allowedTypes.includes(currentFile.type)) {
                setAttachmentError(false);
                setAttachmentErrorMessage('');
                setMainImg(event.target.files[0]);
                event.target.value = null;
            } else {
                setAttachmentError(true);
                setAttachmentErrorMessage(attachmentTypeError);
                event.target.value = null;
            }
        } else {
            setAttachmentError(true);
            setAttachmentErrorMessage(attachmentSizeError);
            event.target.value = null;
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();

        formData.append('mainImg', mainImg);
        formData.append('name', name);
        formData.append('date', date);
        formData.append('online', online);
        formData.append('time', time);
        formData.append('streetAddress', address);
        formData.append('streetAddress2', address2);
        formData.append('city', city);
        formData.append('state', state.value);
        formData.append('zip', zip);
        formData.append('description', description);
        formData.append('active', active);
        formData.append('instructions', instructions);
        formData.append('key', key);
        formData.append('questions', questions);
        axios.post('/api/event', formData, {
            withCredentials: true,
        }).then((res) => {
            if (res.data.status) {
                setError(false);
                setSubmitted(true);
            } else {
                setError(true);
                setErrorMsg(res.data.message);
            }
        })
    }

    const addQuestion = () => {
        const newQuestion = {
            question: "",
            type: "",
        }
        setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
        console.log(questions);
    }

    const updateQuestionAtIndex = (index, question) => {
        const newQuestions = [...questions];
        newQuestions[index].question = question;
        setQuestions(newQuestions);
    }

    const updateQuestionTypeAtIndex = (index, option) => {
        const newQuestions = [...questions];
        newQuestions[index].type = option;
        setQuestions(newQuestions);
    }

    const removeQuestion = (index) => {

    }


    // Renders

    const renderMainImageInfo = () => {
        if (mainImg) {
            return (
                <div>
                    <AttachmentContainer attachment={mainImg}
                        onFileDelete={onFileDelete}
                    />
                </div>
            )

        }
    }

    const renderSubmitButton = () => {
        if (name.length > 0 && date.length > 0
            && description.length > 0 && active != null && instructions.length > 0 && time.length > 0 && mainImg) {
            return (
                <button className="add-submit-button approved-button" onClick={() => setPendingVerification(true)}>Verify & Submit</button>
            )
        }

        return <button className="add-submit-button denied-button" onClick={handleSubmit}>Verify & Submit</button>
    }

    const renderAddQuestionButton = () => {
        if (questions.length < 5) {
            return (
                <>
                    <button className="approved-button" onClick={() => {
                        addQuestion();
                    }}>Add Question</button>
                </>
            )
        } else {
            return (
                <>
                    <button>Maximum Questions Reached</button>
                </>
            )
        }
    }

    // Key Confirm

    const renderError = () => {
        if (error) {
            return <h2 className="error">{errorMsg}</h2>
        }
    }

    const renderKeyConfirm = () => {
        if (pendingVerification) {
            if (!submitted) {
                return (
                    <>
                        <>
                            <div className='pending-verification'>
                                <h1 className='header'>Verify Information</h1>
                                <div className='response-password-input-container'>
                                    <label>
                                        Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                        <Password type="password" name="key" value={key} onChange={(event) => setKey(event.target.value)} toggleMask={true} />
                                    </label>
                                </div>

                                {renderError()}

                                <div className='response-buttons'>
                                    <div className='response-buttons'>
                                        <div>
                                            <button className='denied-button' onClick={() => setPendingVerification(false)}>Go Back</button>
                                        </div>
                                        <div>
                                            <button className='approved-button' onClick={handleSubmit}>Submit Event</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    </>
                )
            } else {
                return (
                    <>
                        <div className='response-submitted'>
                            <h1 className='header'>Event Added!</h1>
                            <h2>The event has been submitted to the database and will display immediately!</h2>
                        </div>
                    </>
                )
            }
        }
    }

    const renderAddressForm = () => {
        if (!online) {
            return (
                <>
                    <div className="event-row">
                        <label for="address1">
                            Street Address: {address.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                            <InputText id='name' value={address} onChange={(event) => setAddress(event.target.value)} />
                        </label>

                        <label for="address2">
                            Street Address 2:

                            <InputText value={address2} onChange={(event) => setAddress2(event.target.value)} />
                        </label>

                        <label for="city">
                            City: {city.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                            <InputText value={city} onChange={(event) => setCity(event.target.value)} />
                        </label>

                    </div>

                    <div className="two-item-section">
                        <div className="section-item">
                            <label>
                                State: {state ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={state}
                                    onChange={(option) => setState(option)} options={StateOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                ZIP: {zip.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                <InputText value={zip} onChange={(event) => setZip(event.target.value)} />
                            </label>
                        </div>



                    </div>
                </>
            )
        }
    }

    if (!pendingVerification) {
        return (
            <>
                <div className='event-add'>
                    <div className="section-container">
                        <h1 className="header">Add An Event</h1>
                        <div className="event-row">
                            <label for="name">
                                Event Name: {name.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                <InputText id='name' value={name} onChange={onNameInput} />
                            </label>

                            <label for="date">
                                Event Date: {date.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                <InputMask mask="99/99/9999" slotChar="mm/dd/yyyy" value={date} onChange={onDateInput} />
                            </label>

                        </div>

                        <div>
                            <label for="description">
                                Event Description:
                            </label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="description" value={description} onChange={onDescriptionInput} />
                        </div>

                        <div className="active-section">
                            <label>
                                <h2>
                                    Event Active?
                                </h2>

                            </label>

                            <div className="event-buttons-container">
                                <button className={active ? "" : "declined"} onClick={() => setActive(false)}>No</button>
                                <button className={active ? "approved" : ""} onClick={() => setActive(true)}>Yes</button>
                            </div>
                        </div>

                        <h1 className="header">Event Address</h1>

                        <div className="active-section">
                            <label>
                                <h2>Event Online?</h2>
                            </label>

                            <div className="event-buttons-container">
                                <button className={online ? "" : "declined"} onClick={() => setOnline(false)}>No</button>
                                <button className={online ? "approved" : ""} onClick={() => setOnline(true)}>Yes</button>
                            </div>
                        </div>

                        {renderAddressForm()}

                        <div className="event-time">
                            <label>
                                Time: {time.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                <InputText value={time} onChange={(event) => setTime(event.target.value)} />
                            </label>
                        </div>

                        <div className="event-instructions">
                            <h1 className="header">Event Questions</h1>
                            <p>Add up to 5 questions for users to respond to, categorized as yes or no questions or text questions.</p>
                            <p>For best results please limit instructions to 1 sentence, for example: </p>
                            <p>"Please list children attending."</p>
                            {renderAddQuestionButton()}
                            {questions.map((question, index) => {
                                return (
                                    <>
                                        <label className="question-header-label">
                                            <h2>Question {index + 1}:</h2>
                                        </label>
                                        <div className="question-row">
                                            <div className="question-content-container">
                                                <label className="question-label">
                                                    Question Content: {question.question ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                                </label>
                                                <InputTextarea value={question.question} onChange={(event) => {
                                                    updateQuestionAtIndex(index, event.target.value);
                                                }} />
                                            </div>

                                            <div className="question-type-container">
                                                <label>
                                                    Question Type: {question.type ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                                    <Select
                                                        styles={selectStyles}
                                                        menuPortalTarget={document.body}
                                                        menuPosition={'fixed'}
                                                        value={question.type}
                                                        onChange={(option) => updateQuestionTypeAtIndex(index, option)} options={questionOptions} />
                                                </label>
                                            </div>

                                            <div className="question-remove-container">
                                                <button className="denied-button" onClick={() => { removeQuestion(index) }}>Remove Question</button>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>


                    <div className="section-container">
                        <h1 className="header">Upload Main Event Image</h1>
                        <p>The main event image can be something like a logo, a picture of the location, or anything else you find appropriate.</p>
                        <p>This image will be displayed beside whatever text you put as the description, so choose wisely!</p>
                        {renderMainImageInfo()}
                        <div className="attachment-button-row">

                            <label onChange={onFileUpload} htmlFor="formId">
                                <input name="" type="file" id="formId" hidden />
                                <div className="attachment-button approved-button">
                                    Add File
                                </div>
                            </label>

                            {attachmentError ? <h2>Error Submitting, Try Again or Contact Support</h2> : ""}
                        </div>

                        {renderSubmitButton()}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className='event-add'>
            <div className="section-container">
                <h1 className="header">Verify & Submit Event</h1>
                {renderKeyConfirm()}
            </div>
        </div>
    )

    // Main Render
}

export default EmployeeEventAdd;