import React, { useState } from 'react'

export default function PermissionContainer(props) {
    const permissionName = props.name;
    const [enabled, setEnabled] = useState(props.enabled);

    // Takes boolean
    const togglePermission = (value) => {
        if (enabled !== value) {
            setEnabled(value);
        }

        props.updatePermissions(permissionName);
    }

    return (
        <>
            <div className='permission-toggle-display'>
                <h3 className='permission-name'>{permissionName} {enabled ? <i class="fa-solid fa-check" />
                    : <i class='fa-solid fa-xmark' />}</h3>
                <div className='permission-buttons'>
                    <button className={enabled ? 'no' : 'declined no'} onClick={() => {
                        togglePermission(false);
                    }}>Disable</button>
                    <button className={enabled ? 'approved yes' : 'yes'} onClick={() => {
                        togglePermission(true);
                    }}>Enable</button>
                </div>
            </div>
        </>
    )
}