import React from "react";
export default function LoadingSection(props) {
    return (
        <>
            <div className='display-container'>
                <h1 className='header'>{props.sectionHeader}</h1>
                <p>Loading section...</p>
            </div>
        </>
    )
}