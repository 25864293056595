import React, { useState, useLayoutEffect } from 'react';

// Import react-reveal for animation handling
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

// Import custom styles
import "./HomeHeaderStyles.css";
import LogoAnimation from './LogoAnimation';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function HomeHeader() {
    const [width, height] = useWindowSize();

    const [animationComplete, setAnimationComplete] = useState(false);

    const renderInfobox = () => {
        if (width <= 1726) {
            return (
                <Fade delay={800}>
                    <div className="infobox-container">
                        <h1 className='header highlight'>Fast and Caring Support</h1>
                        <p>HUGS is a 501(C)3 non profit organization established in 2018 based in Mason, Michigan.</p>
                        <p>
                            We aim to reduce stress during difficult times by providing assistance to individuals and families.
                        </p>
                        <p>We do this through advocacy, education, and financial support.</p>
                    </div>
                </Fade>
            )
        }

        return (
            <Fade bottom delay={800}>
                <div className="infobox-container">
                    <h1 className='header highlight'>Fast and Caring Support</h1>
                    <p>HUGS is a 501(C)3 non profit organization established in 2018 based in Mason, Michigan.</p>
                    <p>
                        We aim to reduce stress during difficult times by providing assistance to individuals and families.
                    </p>
                    <p>We do this through advocacy, education, and financial support.</p>
                </div>
            </Fade>
        )
    }

    const renderText = () => {
        if (width > 930) {
            return (
                <Fade delay={250}>
                    <div className="header-text-item">
                        <div className="header-text">
                            <h1>Hearts Unite</h1>
                            <h2>to Give Support</h2>
                        </div>
                    </div>
                </Fade>
            )
        }
    }

    const renderImage = () => {
            if (!animationComplete) {
                return (
                    <div className="header-text-item">
                        <LogoAnimation setAnimationComplete={setAnimationComplete} />
                    </div>
                )
            } else {
                return (
                    <>
                        <div className="header-text-item">
                            <Bounce>
                                <img className="hugs-logo-image" src="/images/hugs-logo.png" />
                            </Bounce>
                        </div>
                    </>
                )
            }
    }



    return (
        <>
            <Fade>
                <div className="header-container">
                    <div className='header-flexbox'>
                        {renderText()}
                        {renderImage()}
                    </div>

                    {renderInfobox()}
                </div>
            </Fade>

        </>
    )
}

export default HomeHeader;