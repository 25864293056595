import React, { useState } from 'react';
import axios from 'axios';

import { InputTextarea } from 'primereact/inputtextarea';

// Custom Styles
import "./NotesDisplayStyles.css";

const defaultDisplay = 0;
const deleteDisplay = 1;

export default function NotesDisplay(props) {
    // State Handling
    const [note, setNote] = useState("");
    const [noteFormShowing, setNoteFormShowing] = useState(false);
    const [noteSubmitted, setNoteSubmitted] = useState(false);
    const [forDeletion, setForDeletion] = useState([]);
    const [notes, setNotes] = useState(props.notes);
    const [displaying, setDisplaying] = useState(defaultDisplay);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const userNotes = props.userNotes;
    const applicationNotes = props.applicationNotes;
    const id = props.id;
    const token = props.token;

    // Events

    const onToggleNoteForm = () => {
        setNoteFormShowing(!noteFormShowing);
    }

    const onNoteInput = (event) => {
        setNote(event.target.value);
    }

    const onSubmitNote = (event) => {
        event.preventDefault();

        if (applicationNotes) {
            axios.post('/api/add-application-note', {
                withCredentials: true,
                data: {
                    token: token,
                    note: note,
                }
            }).then((res) => {
                setNoteSubmitted(res.data.status);
                if (res.data.status) {
                    setNotes(res.data.notes);
                    props.onNotesUpdate(res.data.notes);
                }
            })
        }

        if (userNotes) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('note', note);
            axios.post('/api/add-user-note', formData, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                setNoteSubmitted(res.data.status);
                if (res.data.status) {
                    setNotes(res.data.notes);
                    props.onNotesUpdate(res.data.notes);
                } else {
                    setError(true);
                    setErrorMsg(res.data.message);
                }
            })
        }

    }

    const onDeleteNotes = (event) => {
        event.preventDefault();

        if (applicationNotes) {
            const newNotes = [];
            notes.forEach(element => {
                if (!forDeletion.includes(element)) {
                    newNotes.push(element);
                }
            });
            axios.post('/api/delete-application-notes', {
                withCredentials: true,
                data: {
                    token: token,
                    notes: newNotes,
                }
            }).then((res) => {
                if (res.data.status) {
                    setDeleteSubmitted(true);
                    setNotes(res.data.notes);
                    props.onNotesUpdate(notes);
                }
            })
        }

        if (userNotes) {
            const newNotes = [];
            notes.forEach(element => {
                if (!forDeletion.includes(element)) {
                    newNotes.push(element);
                }
            });
            axios.post('/api/delete-user-notes', {
                withCredentials: true,
                data: {
                    id: id,
                    notes: newNotes,
                }
            }).then((res) => {
                if (res.data.status) {
                    setDeleteSubmitted(true);
                    setNotes(res.data.notes);
                    props.onNotesUpdate(notes);
                }
            })
        }
    }

    // Renders

    const renderNotesButton = () => {
        if (notes.length === 0) {
            return <button className={!noteFormShowing ? 'only-add approved-button' : 'only-add denied-button'} onClick={onToggleNoteForm}>{!noteFormShowing ? 'Add Note' : 'Go Back'}</button>
        } else {
            return (
                <div className='notes-control'>
                    <ul className='notes-control-list'>
                        <li>
                            <button className='denied-button' onClick={() => {
                                if (displaying != deleteDisplay) {
                                    setDisplaying(deleteDisplay);
                                    setForDeletion([]);
                                } else {
                                    setDisplaying(defaultDisplay);
                                }

                            }}>Delete Note</button>
                        </li>

                        <li>
                            <button className={!noteFormShowing ? 'approved-button' : 'denied-button'} onClick={onToggleNoteForm}>{!noteFormShowing ? 'Add Note' : 'Go Back'}</button>
                        </li>
                    </ul>
                </div>
            )
        }
    }


    const renderNoteForm = () => {
        if (!noteSubmitted) {
            if (noteFormShowing) {
                return (
                    <>
                        <div className='note-form'>
                            <form>
                                <div className='note-input'>
                                    <InputTextarea autoResize={true} rows={5} cols={30}
                                        id="assistanceDetails" value={note} onChange={onNoteInput} />
                                </div>

                                <div>
                                    <button className='approved-button' onClick={onSubmitNote}>Submit Note</button>
                                </div>
                            </form>
                        </div>
                    </>
                )
            }
        } else {
            return (
                <>
                    <h2>Note Submitted!</h2>
                </>
            )
        }
    }

    const renderDeleteNotesButton = () => {
        if (forDeletion.length > 0) {
            return <button className="approved-button" onClick={onDeleteNotes}>Delete Selected</button>
        }

        return <button className="denied-button">Delete Selected</button>
    }

    const renderNotes = () => {
        switch (displaying) {
            case (defaultDisplay):
                return (
                    <>
                        <div className='notes'>
                            <h1 className='header'>Notes</h1>
                            <ul className='notes-list'>
                                {notes.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <h1 className='header note-header'>Note {index + 1}</h1>
                                            <h2 className='header'>Added by {item.name} on {item.date}</h2>
                                            <p>{item.body}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                            {renderNoteForm()}
                            {renderNotesButton()}
                        </div>
                    </>
                )

            case (deleteDisplay):
                return (
                    <>
                        <div className='notes'>
                            <h1 className='header'>Notes</h1>
                            <ul className='notes-list'>
                                {notes.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <div className='note-for-deletion'>
                                                <h1 className='header'>Note {index + 1}</h1>
                                                <button className='delete-note-button' onClick={() => {
                                                    if (forDeletion.includes(item)) {
                                                        setForDeletion(forDeletion.filter(e => e !== item));
                                                    } else {
                                                        setForDeletion(forDeletion.concat(item));
                                                    }
                                                }}>{forDeletion.includes(item) ? <i class="fa-solid fa-check"></i> : <i class="fa-solid fa-x"></i>}</button>
                                            </div>
                                            <h2 className='header'>Added by {item.name} on {item.date}</h2>
                                            <p>{item.body}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                            {deleteSubmitted ? <h2>Notes Deleted!</h2> : ""}
                            <ul className='notes-control-list'>
                                <li>
                                    <button className='denied-button' onClick={() => setDisplaying(defaultDisplay)}>Go Back</button>
                                </li>

                                <li>
                                    {renderDeleteNotesButton()}
                                </li>

                            </ul>
                        </div>
                    </>
                )
        }
    }


    return (
        <>
            {renderNotes()}
        </>
    )
}