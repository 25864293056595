import React, { useEffect, useRef } from 'react';
import anime from 'animejs';

var delayAmount = 200;
var durationAmount = 1000;

export default function LogoAnimation(props) {
    const svgRef = useRef(null);

    function flagAnimationComplete () {
        props.setAnimationComplete(true);
    }

    useEffect(() => {
        const svg = svgRef.current;
        const paths = svg.querySelectorAll('path');
        var increment = 0;
        setInterval(flagAnimationComplete, (paths.length * delayAmount) + 500);
        paths.forEach(path => {
            const totalLength = path.getTotalLength();
            path.style.strokeDasharray = totalLength;
            path.style.strokeDashoffset = totalLength;
            // Initial offset

            anime({
                targets: path,
                strokeDashoffset: [anime.setDashoffset, 0], // Maintain dashoffset behavior
                strokeOpacity: [0, 1], // Animate opacity from 0 to 1
                easing: 'easeInOutSine',
                duration: durationAmount,
                delay: increment * delayAmount,
            });
            increment++;
        });
    }, []);

    return (
        <svg ref={svgRef} className='svg-logo-animation' version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="300.000000pt" height="330.000000pt" viewBox="0 0 300.000000 330.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
                Created by potrace 1.10, written by Peter Selinger 2001-2011
            </metadata>
            <g transform="translate(0.000000,330.000000) scale(0.100000,-0.100000)"
                fill="#ffffff00" stroke="#FFFFFF" stroke-width='8'>
                {/* Top Right */}
                <path stroke='#ed1b24' d="M476 3024 c-212 -47 -375 -209 -431 -429 -10 -38 -16 -105 -16 -182
       0 -188 43 -369 132 -562 38 -83 147 -256 155 -248 3 2 -2 60 -10 128 -23 196
       -28 325 -17 445 15 149 42 250 97 362 169 345 519 394 879 124 44 -33 100 -80
       125 -106 24 -25 48 -46 53 -46 26 0 -221 264 -316 337 -191 148 -458 220 -651
       177z m339 -54 c39 -12 90 -32 115 -43 60 -27 184 -107 177 -113 -3 -3 -45 6
       -92 21 -163 49 -282 44 -410 -19 -187 -92 -305 -294 -346 -591 -10 -76 -8
       -395 4 -446 1 -6 -3 -13 -9 -16 -23 -8 -110 192 -156 362 -39 143 -50 352 -23
       455 41 162 147 300 279 364 101 49 134 56 271 52 90 -3 137 -9 190 -26z"/>
                {/* Top Left */}
                <path stroke='#ed1b24' d="M2143 3260 c-254 -46 -463 -266 -581 -615 -53 -155 -85 -353 -38
       -230 87 225 235 430 379 523 90 59 174 84 279 85 81 0 93 -2 162 -37 137 -67
       261 -234 306 -412 25 -95 25 -135 1 -249 -22 -104 -73 -246 -142 -394 -27 -57
       -49 -105 -49 -107 0 -2 28 13 63 33 34 19 96 52 137 73 64 33 80 45 107 91
       153 256 180 490 87 769 -19 58 -53 136 -76 174 -78 132 -212 237 -358 280 -68
       20 -208 28 -277 16z m222 -35 c133 -26 278 -123 357 -239 155 -227 195 -551
       97 -788 -40 -99 -85 -173 -121 -203 -33 -28 -123 -75 -144 -75 -7 0 3 34 26
       88 114 267 141 420 101 575 -36 140 -124 286 -219 368 -126 107 -297 135 -455
       75 -145 -56 -211 -110 -360 -296 l-27 -35 19 52 c41 112 132 249 225 336 76
       71 192 127 301 144 17 3 31 6 33 8 4 3 140 -4 167 -10z"/>
                {/* Bottom */}
                <path stroke='#ed1b24' d="M948 1063 c78 -94 202 -199 327 -278 33 -21 61 -39 63 -40 1 -1 -15
       -36 -37 -76 -51 -95 -72 -172 -72 -256 0 -111 34 -187 124 -280 60 -63 60 -63
       116 -62 l56 0 -50 16 c-172 52 -239 255 -156 474 18 47 78 149 87 149 3 0 28
       -12 57 -26 53 -27 182 -74 201 -74 6 0 -29 22 -79 48 -49 27 -105 58 -123 69
       l-33 20 95 88 c84 77 245 208 316 257 24 17 23 17 -66 17 l-92 1 -114 -112
       c-63 -62 -133 -135 -155 -163 l-40 -49 -74 59 c-91 72 -190 171 -225 225 l-27
       40 -69 0 -70 0 40 -47z m792 9 c0 -8 -23 -28 -104 -92 -11 -8 -40 -33 -65 -55
       -25 -21 -57 -48 -70 -60 l-25 -20 21 30 c24 34 156 163 193 188 26 18 50 22
       50 9z m-669 -54 c69 -82 77 -94 59 -83 -24 14 -130 118 -130 127 0 22 31 3 71
       -44z m339 -258 c-22 -31 -40 -39 -40 -17 0 10 44 46 58 47 2 0 -6 -13 -18 -30z"/>
                {/* H */}
                <path d="M496 2451 c-8 -125 -8 -1067 0 -1202 l7 -104 96 2 c72 1 95 5 93 14
       -2 7 -6 141 -9 297 l-6 285 24 -6 c13 -4 27 -7 30 -7 3 0 9 -109 13 -242 3
       -134 9 -265 12 -293 l6 -50 90 0 90 0 -6 45 c-6 40 -8 1118 -3 1164 2 14 -14
       24 -68 45 -38 15 -80 33 -92 40 l-23 12 0 -94 c0 -52 -3 -149 -7 -215 l-6
       -122 -30 0 -30 0 4 226 4 225 -91 38 -91 37 -7 -95z m97 14 c20 -8 39 -20 44
       -27 4 -7 6 -101 4 -208 l-3 -195 27 -28 c33 -32 65 -35 91 -6 16 18 20 47 29
       197 11 195 9 190 75 159 l25 -12 2 -578 2 -577 -43 0 c-37 0 -45 3 -49 23 -3
       12 -9 132 -13 267 -6 228 -8 247 -27 268 -29 32 -81 30 -103 -3 -15 -23 -16
       -56 -13 -288 l4 -262 -50 0 -50 0 -7 45 c-8 57 -10 1152 -2 1203 7 42 9 42 57
       22z"/>
                {/* U */}
                <path d="M1012 1849 c4 -550 5 -559 63 -636 39 -50 84 -66 171 -61 124 8 176
       62 194 200 5 40 10 265 10 499 l0 426 -84 7 c-46 3 -90 8 -98 11 -11 4 -13
       -77 -10 -468 2 -415 1 -476 -13 -487 -8 -7 -22 -10 -30 -6 -13 5 -15 66 -15
       488 l0 483 -82 19 c-45 10 -88 20 -95 23 -12 5 -14 -70 -11 -498z m105 431 c6
       0 19 -7 27 -16 14 -14 16 -70 16 -468 l0 -453 23 -20 c34 -31 45 -35 68 -23
       47 25 49 50 49 510 l0 431 48 -3 47 -3 0 -470 c0 -469 0 -470 -22 -506 -32
       -50 -86 -73 -162 -67 -50 3 -66 9 -91 33 -62 59 -64 74 -68 569 -2 247 -1 460
       3 474 5 19 11 23 28 18 12 -3 27 -6 34 -6z"/>
                {/* G */}
                <path d="M1733 2319 c-103 -20 -169 -70 -194 -147 -17 -50 -24 -697 -10 -806
       13 -94 48 -177 87 -202 19 -13 46 -19 85 -19 62 0 86 14 119 68 l17 28 7 -48
       6 -48 60 0 60 0 0 361 0 362 -37 -8 c-20 -5 -69 -12 -110 -15 l-73 -7 0 -100
       c0 -95 1 -99 20 -93 20 6 20 3 18 -146 -3 -127 -6 -154 -19 -162 -9 -6 -24 -7
       -33 -4 -14 6 -16 47 -16 425 0 452 1 458 49 432 19 -10 21 -20 21 -120 0 -90
       3 -110 15 -110 8 0 49 5 91 11 l76 12 -4 126 c-3 101 -7 132 -23 158 -34 58
       -102 74 -212 52z m159 -56 c25 -22 38 -76 38 -158 0 -82 -9 -95 -62 -95 l-38
       0 0 79 c0 71 -3 83 -26 111 -33 38 -77 38 -106 0 -17 -24 -18 -51 -18 -439 0
       -411 0 -415 21 -438 29 -30 53 -37 79 -23 39 21 51 70 49 204 -1 103 -4 129
       -20 155 -19 32 -25 105 -10 129 4 8 31 14 67 15 l59 2 0 -305 c0 -274 -2 -305
       -16 -308 -10 -2 -25 11 -39 32 -12 20 -28 36 -36 36 -8 0 -30 -15 -50 -34 -40
       -37 -61 -44 -114 -36 -25 4 -41 14 -57 39 -46 68 -48 93 -48 511 0 442 0 442
       69 495 67 51 215 67 258 28z"/>
                {/* S */}
                <path d="M2237 2496 c-90 -33 -153 -103 -182 -200 -18 -60 -20 -251 -4 -328
       16 -73 37 -111 109 -193 34 -38 75 -92 91 -120 27 -47 29 -56 29 -166 0 -92
       -3 -119 -15 -129 -12 -10 -18 -10 -30 0 -12 10 -15 40 -15 152 l0 140 -57 -6
       c-32 -4 -73 -10 -92 -13 l-33 -6 5 -151 c6 -199 26 -255 108 -305 40 -25 132
       -28 182 -7 41 17 91 73 108 123 10 27 14 94 13 238 0 181 -2 206 -22 259 -26
       69 -68 138 -134 217 -79 95 -88 115 -88 195 0 54 4 77 18 94 17 21 19 21 35 5
       13 -13 17 -35 17 -100 l0 -84 28 4 c15 2 52 7 82 10 l55 6 -5 137 c-6 148 -17
       187 -65 218 -34 22 -93 27 -138 10z m115 -47 c34 -18 48 -70 48 -178 0 -99 0
       -101 -25 -107 -43 -11 -52 0 -57 66 -3 49 -9 66 -30 87 -58 57 -118 -4 -118
       -120 0 -85 12 -113 92 -213 139 -175 153 -218 153 -469 0 -177 -2 -193 -23
       -236 -28 -57 -80 -89 -147 -89 -111 0 -156 73 -163 265 -5 136 1 149 60 143
       l33 -3 3 -111 c2 -102 5 -114 27 -138 32 -34 57 -33 89 5 25 29 26 37 26 143
       0 136 -13 167 -121 296 -109 130 -114 144 -114 335 l0 160 31 55 c40 72 108
       120 171 120 24 0 53 -5 65 -11z"/>
            </g>
        </svg>
    );
}