import React, { useState } from 'react';

// Custom Styles
import "./AttachmentStyles.css";

function AttachmentContainer(props) {
    const name = props.attachment.name;
    const type = props.attachment.type;
    const size = (props.attachment.size / 1024).toFixed(2);

    const renderAttachmentContainer = () => {
        return (
            <div className='attachment-container'>
                <div className='attachment-control-display'>
                    <button className={"file-attachment  approved-button"}>
                        {name} | {type} | {size}kb
                    </button>
                </div>

                <div className='attachment-control-button'>
                    <button className='remove-attachment-button denied-button'
                        onClick={() => props.onFileDelete(props.attachment)} >Remove Attachment</button>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderAttachmentContainer()}
        </>

    )

}

export default AttachmentContainer;